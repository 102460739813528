import React from 'react'

import { ProvideAuth } from '../../components/Auth/useAuth'
import Layout from '../../components/Layout'
import AdminHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import LoginForm from '../../components/Auth/LoginForm'


const AdminLoginPage = () =>
  <ProvideAuth>
    <Layout>
      <AdminHero
        title='Administrator Login'
        body={[]}
      />
      <LoginForm isAdminLoginForm />
    </Layout>

  </ProvideAuth>

export default AdminLoginPage